@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
  margin: 0;
  padding: 0;
  --primary-color: #f35a14;
  --primary-color-1: #ff5e15;
  --white-color: #ffffff;
  --white-color-100: #f1f1f1;
  --dark-color: #000000;
  --dark-color-300: #333333;
  --dark-color-350: #353535;
  --dark-color-400: #343232;
  --dark-color-500: #2b2929;
  --dark-color-600: #222222;
  --grey-color: #888888;
  --dark-grey: #6e6e6e;
  --dark-grey-100: #999999;
  --grey-color-100: #f8f8f8;
  --body-fonts: "Poppins", sans-serif;
  --font-size: 16px;
  --font-color: #666666;
  --dark-blue: #0c4886;
  --dark-blue-100: #0d4c89;
  --dark-shadow-color-1: #000000b3;
  --dark-shadow-color-2: #000000cc;
  --dark-shadow-color-3: #00000029;
  --dark-grey-200: #979797;
  --red-color: red;
  --light-grey-100: #a8a8a8;
  --light-grey-200: #ececec;
  --light-grey-300: #f6f7f8;
  --light-grey-400: #cdcdcd;
  --light-grey-500: #d6d6d6;
  --light-grey-600: #f7f7f8;
  --light-grey-700: #f7f7f8;
  --light-grey-800: #f5f5f5;
  --light-grey-900: #e5e5e5;
  --border-color: #0000002e;
  --border-color-2: #0000001a;
  --border-color-3: #2222221c;
  --border-color-4: #00000005;
  --border-color-5: #3535351f;
  --border-color-6: #2222221f;
  --border-color-7: #c7c7c780;
  --light-border-color: #dedede;
  --light-border-color-100: #9a9a9a;
  --green-color: #54ac3c;
  --red-color-100: #ec2c2c;

  .ant-select-selector {
    border: 1px solid var(--light-grey-400);
    border-radius: 2px;
    font-size: 1rem;
    padding: 10px 16px !important;
    color: var(--dark-color-350);
    min-height: 48px;

    .ant-select-selection-item {
      line-height: 26px;
    }

    .ant-select-selection-search,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }

  .custom--select .ant-select-selector {
    min-height: 42px;
    box-shadow: 0px 3px 20px var(--border-color-4);
    border: 1px solid var(--light-border-color);
    border-radius: 2px;
    max-width: 250px;
    width: 250px;

    .ant-select-selection-item {
      line-height: 18px;
    }

    .ant-select-selection-placeholder {
      font-size: 16px;
      font-family: var(--body-fonts);
      font-weight: 500;
      color: #666666;
    }
  }
}

body {
  font-family: var(--body-fonts);
  font-size: var(--font-size);
  color: var(--font-color);
  line-height: 25px;
  top: 0 !important;

  > .skiptranslate {
    transform: translateY(-40px);
    visibility: hidden;
    top: 90px !important;
    position: absolute !important;
  }
}

:root a {
  text-decoration: none;
}

.container {
  max-width: 1272px;
}

// navbar
.top-info-navbar {
  background-color: var(--dark-blue);
  padding: 17px 0;

  .select-language-dropdown {
    .goog-te-gadget-simple {
      background: transparent;
      color: #fff;
      border: 0;

      a {
        font-size: 16px;

        > span {
          color: #fff !important;

          &:nth-of-type(2) {
            display: none;
          }

          &:last-of-type {
            font-size: 12px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .container {
      max-width: 1300px;
    }
  }
}

.c-nav-link {
  color: var(--white-color);

  &:hover,
  &:focus,
  &:active {
    color: var(--white-color);
  }
}

.top-navbar {
  padding: 20px 0 0 0;
  position: relative;
  z-index: 90;

  > .container {
    @media (max-width: 1400px) {
      max-width: 1300px;
    }
  }

  .navbar {
    background: var(--dark-blue);
    height: 62px;
    margin-top: 20px;
    margin-bottom: -31px;
    padding: 0 0 0 25px;

    .navbar-nav {
      column-gap: 39px;

      & + button.btn--primary {
        padding: 15px 30px;
        height: inherit;
        border: 0;
        display: flex;
        align-items: center;
        column-gap: 10px;
      }

      .nav-item {
        .custom-dropdown {
          height: 100%;

          .dropdown-toggle {
            height: inherit;
          }
        }
      }
    }
  }
}

// dropdown
.custom-dropdown {
  .btn-link {
    color: var(--white-color);
    padding: 0;
    text-decoration: none;
    outline: 0;
    border: 0;
    display: flex;
    align-items: center;

    &::after {
      border: 0;
      content: url(../images/down-chevron.svg);
      transform: translateY(-1px);
      margin-left: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--white-color);
    }
  }

  .dropdown-menu {
    background-color: var(--white-color);
    box-shadow: 0px 3px 20px var(--dark-shadow-color-3);
    border-radius: 0px 0px 4px 4px;
    border: 0;
    top: -2px !important;
    padding: 14px 0;
    width: 100vw;
    max-width: 478px;

    a {
      font-size: 1rem;
      line-height: 22px;
      font-weight: 500;
      letter-spacing: 0.16px;
      color: var(--dark-color);
      padding: 4px 16px;
      white-space: pre-wrap;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: var(--primary-color);
      }

      > a {
        padding: 0;
      }
    }
  }

  &.resources-dropdown {
    .dropdown-menu {
      max-width: 387px;
    }
  }
}

.v-divider {
  width: 1px;
  height: 16px;
  background: var(--white-color);
}

.hr--divider {
  border-bottom: 0.5px solid var(--border-color-2);

  &.border-light-grey {
    border-bottom-color: var(--light-grey-900);
  }
}

// button
:root .btn.btn {
  font-family: var(--body-fonts);

  &--primary {
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.45px;
    padding: 13px 20px;
    border: 0;

    &-md {
      height: 48px;
      padding: 12px 47px;
      font-size: 16px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--primary-color);
      color: var(--white-color);
    }

    &--200 {
      border-radius: 2px;
      max-width: 200px;
    }
  }

  &-sm {
    height: 38px;
  }

  &--primary__custom {
    border-radius: 2px;
    letter-spacing: 0px;
    color: var(--white-color);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    padding: 8px 56px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }

  &-borderd--primary {
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: var(--primary-color);
    font-weight: 500;
    padding: 10px 17px;

    a {
      color: var(--primary-color);
    }
  }
}

.w-400 {
  max-width: 400px;
}

.w-180 {
  max-width: 180px;
}

.w-200 {
  max-width: 200px;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
}

.calculate-shipping-modal.modal-body {
  padding: 20px 40px !important;
}

.max-height-400 {
  max-height: 400px;
}

// title
.block-title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dark-color);
  line-height: 30px;

  &-md {
    font-size: 1.125rem;
  }

  &-20 {
    font-size: 20px;
    line-height: 30px;
  }

  &-xl-22 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
  }

  &-xl {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: var(--dark-color-300);
  }

  &-xxl {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 1.05px;
    font-weight: 700;
  }

  &-xxxl {
    font-size: 48px;
    line-height: 62px;
    letter-spacing: 1.2px;
    font-weight: 700;
  }
}

.cmn-txt {
  font-size: 0.875rem;
  color: var(--grey-color);
  line-height: 24px;
  letter-spacing: 0.4px;
  font-family: var(--body-fonts);

  &-sm {
    font-size: 0.75rem;
    line-height: 18px;
  }

  &-md {
    font-size: 16px;
    line-height: 32px;
  }

  &-xl {
    font-size: 30px;
    line-height: 48px;
  }

  &--15 {
    font-size: 15px;
    line-height: 16px;
  }

  &-13 {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.05px;
  }

  &--18 {
    font-size: 18px;
    line-height: 27px;
  }
}

.img-width img {
  width: auto;
  max-width: 650px;
}

.text {
  &--primary {
    color: var(--primary-color) !important;
  }

  &--dark {
    color: var(--dark-color);
  }

  &--grey {
    color: var(--font-color) !important;
  }

  &--success {
    color: var(--green-color);
  }

  &--warning {
    color: var(--red-color-100) !important;
  }
}

.font-weight {
  &-medium {
    font-weight: 500 !important;
  }

  &-semibold {
    font-weight: 600 !important;
  }
}

// modal
.custom-modal {
  &-sm {
    .modal-dialog {
      max-width: 565px;
    }
  }

  &-md {
    .modal-dialog {
      max-width: 941px;
    }
  }

  .modal-content {
    border-radius: 4px;

    .modal-header {
      padding: 16px 40px;
      border-bottom: 0.5px solid var(--border-color-3);

      .modal-title {
        font: normal normal 600 22px/33px Poppins, sans-serif;
        letter-spacing: 0px;
        color: var(--dark-color);
      }
    }

    .modal-body {
      padding: 20px 58px;
    }
  }

  &.address--modal {
    .modal-body {
      padding: 20px 40px;
    }
  }
}

// custom field
.custom-field {
  .ant-form-item-label {
    text-align: left;

    label {
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0px;
      color: var(--dark-color);
    }
  }

  > .ant-form-item-row {
    flex-direction: column;
  }
}

.custom-form-control.form-control {
  border: 1px solid var(--light-grey-400);
  border-radius: 2px;
  font-size: 1rem;
  padding: 16px;
  color: var(--dark-color-350);

  &::placeholder {
    color: var(--dark-color-350);
    opacity: 0.5;
  }

  &:not(textarea) {
    height: 48px;
  }

  &.date-picker {
    padding: 10px 16px;
  }
}

:root .ant-checkbox-wrapper {
  font: normal normal normal 16px/25px Poppins, sans-serif;
  letter-spacing: 0px;
  color: var(--light-border-color-100);

  .ant-checkbox-inner {
    border: 2px solid var(--light-border-color-100) !important;
    border-radius: 4px !important;
    background: transparent !important;

    &::after {
      border: 2px solid var(--light-border-color-100) !important;
      border-top: 0 !important;
      border-left: 0 !important;
      top: 7px;
      width: 5px;
      height: 9px;
    }
  }

  .ant-checkbox {
    border-color: transparent !important;
  }
}

.ant-date-picker-custom {
  z-index: 1056;
}

// link
.a-r-o-link {
  letter-spacing: 0px;
  color: var(--dark-blue);
  font-size: 1rem;
  font-weight: 500;
}

.d-w-txt {
  max-width: 228px;
  margin: 0 auto;
  text-align: center;
  position: relative;

  &::after {
    content: "";
    border-bottom: 1px solid var(--dark-grey-100);
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
  }

  span {
    background: var(--white-color);
    position: relative;
    z-index: 1;
    padding: 0 10px;
    font: normal normal normal 14px/60px Poppins, sans-serif;
    letter-spacing: 0px;
    color: var(--dark-grey-100);
  }
}

.b-t-w-l {
  letter-spacing: 0px;
  color: var(--dark-color);
  font-size: 16px;
  font-weight: 500;
}

.float-close-btn {
  .btn-close {
    position: absolute;
    right: 40px;
    top: 25px;
  }
}

.me {
  &-152 {
    margin-right: 152px;
  }
}

.banner-slider {
  position: relative;

  .carousel-inner {
    height: 100%;
    margin-top: 1%;

    .carousel-item {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .carousel-caption {
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;

        h3 {
          font: normal normal 600 48px/68px Poppins, sans-serif;
          letter-spacing: 1.2px;
          color: var(--white-color);
          margin: 0 auto 29px;
          max-width: 796px;
        }

        p {
          font: normal normal normal 20px/30px Poppins, sans-serif;
          letter-spacing: 0.5px;
          color: var(--light-grey-500);
          max-width: 946px;
          margin: 0 auto;
        }
      }
    }
  }

  a {
    &[class^="carousel-control"] {
      height: 40px;
      width: 40px;
      top: 50%;
      transform: translateY(-50%);
      background: #6e6e6eb3;
      opacity: 1;
      border-radius: 50%;

      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        width: 1.5rem;
      }
    }

    &[class="carousel-control-prev"] {
      left: 25px;
    }

    &[class="carousel-control-next"] {
      right: 25px;
    }
  }

  .carousel-indicators {
    display: none;
  }

  &::after {
    content: none;
    left: 0;
    height: 100%;
    width: 100%;
    background: transparent
      radial-gradient(
        closest-side at 50% 50%,
        var(--dark-color-300) 0%,
        var(--dark-color-300) 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.9;
    position: absolute;
    top: 0;
  }

  &.product-img-preview-slider {
    height: auto;

    a[class="carousel-control-prev"] {
      left: 0;
    }

    a[class="carousel-control-next"] {
      right: 0;
    }

    a[class^="carousel-control"] {
      background: #bebebe;
    }

    .carousel-inner {
      display: flex;
      height: 480px;
      align-items: center;

      .carousel-item {
        text-align: center;
        height: min-content;

        img {
          width: auto;
          height: auto;
          max-height: 100%;
          object-fit: contain;
          max-width: 681px;
        }
      }
    }

    &::after {
      display: none;
    }
  }
}

// footer
.footer {
  background-color: var(--dark-blue-100);
  padding: 25px 0;

  address {
    @extend .cmn-txt;
    color: var(--white-color);
  }

  .sub-footer {
    padding: 20px 0;
    background: rgba(0, 0, 0, 0.1);
    margin-top: 45px;
  }
}

.footer-company-desc {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: var(--white-color);
  opacity: 0.8;
  max-width: 312px;
  margin-top: 12px;
}

.footer-link-list {
  > li {
    flex: 1 1 50%;
    max-width: 50%;
    margin-top: 5px;

    a {
      @extend .cmn-txt;
      color: var(--white-color);
    }
  }
}

.c__label {
  font: normal normal 600 18px/28px Poppins, sans-serif;
  letter-spacing: 0px;
  color: var(--white-color);
  margin-bottom: 20px;
}

// about
.about-block {
  padding: 90px 0 96px;
}

.max-width {
  &-648 {
    max-width: 648px;
  }

  &-514 {
    max-width: 514px;
  }
}

// product categories
.product-categories-block {
  background: linear-gradient(
    to bottom,
    var(--dark-blue) calc(100%),
    var(--white-color) 100px
  );
  padding: 58px 0 0;
}

.product-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  background: var(--light-grey-300);
  padding: 24px;

  img {
    max-height: 100%;
  }
}

.service-manual-documentation-training-block {
  padding: 107px 0 50px;

  .block-title {
    position: absolute;
    max-width: 450px;
    bottom: 95px;
    left: 94px;
  }
}

.online-store-block {
  padding: 50px 0 150px;
}

.card {
  &.custom-card {
    border: 1px solid var(--light-border-color);
    border-radius: 0;
    box-shadow: none;
    max-width: 400px;
    
    &.product-card {
      .card-body {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--light-grey-300);
        padding: 0;

        > img {
          max-height: 100%;
          object-fit: contain;
        }
      }

      .card-footer {
        padding: 18px;
        text-align: center;
        border: 0;
        background-color: var(--white-color);

        a {
          font-size: 22px;
          line-height: 30px;
          font-weight: 600;
          letter-spacing: 0.22px;
          color: var(--dark-color);

          &:hover {
            color: var(--primary-color);
            text-decoration: underline;
          }
        }
      }
    }

    &.tutorial-card {
      border: 0;

      .card-header {
        padding: 0;
        height: 350px;
        border-radius: 2px;
        overflow: hidden;
        border: 0;

        iframe {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .card-body {
        padding: 20px 0;
      }
    }
  }
}

.list-banner-block {
  height: 217px;
  background: url(../images/truckimg.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 48px;

  &::after {
    content: "";
    background: transparent
      radial-gradient(
        closest-side at 50% 50%,
        var(--dark-shadow-color-1) 0%,
        var(--dark-shadow-color-2) 100%
      )
      0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  color: var(--white-color) !important;
}

.product {
  &--title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 0.16px;
    color: var(--dark-color);
    margin-bottom: 6px;
  }

  &--price {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 0.16px;
    color: var(--primary-color);
    margin-bottom: 0;

    del {
      color: var(--dark-grey-100);
      font-weight: 400;
    }

    &--md {
      font-size: 20px;
      letter-spacing: 0.2px;
    }
  }
}

.custom--select.form-control {
  box-shadow: 0px 3px 20px var(--border-color-4);
  border: 1px solid var(--light-border-color);
  border-radius: 2px;
  max-width: 216px;
  font-size: 16px;
  line-height: 38px;
  letter-spacing: 0.24px;
  color: var(--font-color);
  background: var(--white-color) url(../images/Up.svg) no-repeat;
  background-position: right 10px center;
  padding: 0 30px 0 15px;
  position: relative;
  z-index: inherit;
}

.product-hover-card {
  .card-body {
    position: relative;

    &:hover {
      .overlay {
        display: flex;
      }
    }
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(255, 94, 21, 0.8);
    display: none;
  }

  .btn--custom {
    background: var(--dark-blue);
    font-size: 14px;
    color: var(--white-color);
    display: flex;
    align-items: center;
    border-radius: 2px;

    &:hover,
    &:focus,
    &:active {
      background: var(--dark-blue);
      color: var(--white-color);
    }
  }
}

.product--detail__info {
  display: table;

  .table-row {
    display: table-row;

    .title {
      display: table-cell;
      font: normal normal normal 15px/16px Poppins, sans-serif;
      letter-spacing: 0px;
      color: var(--dark-color);
    }

    .info {
      display: table-cell;
      font: normal normal normal 15px/16px Poppins, sans-serif;
      letter-spacing: 0px;
      padding: 4px 6px;
      color: var(--font-color);

      a {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }
  }
}

.product--detail_table {
  table {
    border: 0.5px dashed var(--border-color);
    width: 100%;

    tr {
      border-bottom: 0;
    }

    th,
    td {
      padding: 12px 20px;
      border-bottom: 0.5px dashed var(--border-color);
      border-right: 0.5px dashed var(--border-color);
      font-size: 15px;
      line-height: 32px;
    }

    th {
      width: 30%;
      color: var(--dark-color);
    }

    td {
      color: var(--font-color);
    }
  }
}

.product--detail__custom-input-group {
  border: 0.5px solid var(--border-color-5);
  border-radius: 2px;
  height: 42px;
  max-width: 130px;
  padding: 0 !important;

  .btn {
    background: var(--light-grey-700);
    border: 0;
    border-radius: 2px 0 0 2px;
    height: 100%;
    width: 42px;
    color: var(--primary-color);
    font-size: 18px;
  }

  input.form-control {
    padding: 0 5px;
    letter-spacing: 0px;
    color: var(--dark-color-350);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    box-shadow: none;
    border: 0;
  }

  .input-group-append {
    .btn {
      border-radius: 0 2px 2px 0;
    }
  }
}

.Prodcut--profile__custom-tab {
  border-bottom: 1px solid var(--border-color-6);

  .nav-item {
    button {
      font-size: 18px;
      line-height: 27px;
      color: var(--dark-color-400);
      border: 0;
      padding: 21px 15px;
      position: relative;

      &.active,
      &:hover {
        margin-bottom: 0;
        font-weight: 500;
        color: var(--primary-color);

        &::after {
          content: "";
          height: 2px;
          width: 100%;
          background: var(--primary-color);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

.custom--tab-content {
  padding: 26px 0;
}

.product-desc-list {
  ul {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;

    > li {
      position: relative;
      padding-left: 20px;
      font: normal normal normal 16px/30px Poppins, sans-serif;
      letter-spacing: 0px;
      color: var(--font-color);

      &::before {
        content: "";
        height: 10px;
        width: 10px;
        border: 2px solid var(--primary-color-1);
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 10px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.comment--list {
  margin-bottom: 0;

  > li {
    border-bottom: 1px solid var(--light-grey-200);
    padding-bottom: 20px;
    margin-bottom: 20px;

    .cmnt-txt {
      font: normal normal normal 14px/26px Poppins, sans-serif;
      letter-spacing: 0px;
      color: var(--dark-color-300);
      margin-bottom: 10px;
    }

    p.author-date-txt {
      letter-spacing: 0px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: var(--light-grey-100);
      margin-bottom: 0;

      span {
        color: var(--dark-color-400);
      }
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

// error
.error-msg {
  font-size: 14px;
  color: var(--red-color);
}

// pagination
.paginationitem {
  margin-top: 15px !important;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 550px) {
    flex-wrap: wrap;
    justify-content: center;

    > li:first-child {
      width: 100%;
      text-align: center;
    }
  }
}

.product--details__comment-list {
  max-width: 1105px;
}

.field--label {
  letter-spacing: 0px;
  color: var(--dark-color-300);
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 8px;
}

.product--detail__desc-list {
  > li {
    font: normal normal normal 16px/32px var(--body-fonts);
    letter-spacing: 0px;
    color: #666666;

    &::before {
      content: url(../images/orangeellipsis.svg);
      margin-right: 8px;
    }
  }
}

.paginationitem li {
  font-size: 1em !important;
}

.paginationitem li:first-child {
  margin-right: auto !important;
  color: var(--dark-grey-200) !important;
}

.loader-body {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      rgba(0, 0, 0, 0.7019607843) 0%,
      rgba(0, 0, 0, 0.8) 100%
    )
    0% 0% no-repeat padding-box;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.spinner-border {
  height: 100px;
  width: 100px;
  border-width: 10px;

  &.text-warning {
    border-color: var(--primary-color);
    border-right-color: transparent;
  }
}

.accordion-button {
  &:after {
    background-image: url(../images/plusicon.svg) !important;
    background-size: 16px !important;
    background-position: center;
  }

  &:not(.collapsed)::after {
    background-image: url(../images/minusicon.svg) !important;
  }
}

.custom-accordion {
  border-radius: 2px;

  .accordion-item {
    border-radius: 2px !important;
    border-color: var(--border-color-7);

    .accordion-button {
      font: normal normal 600 16px/40px var(--body-fonts);
      letter-spacing: 0.4px;
      color: var(--dark-color);
      padding: 6px 24px;
      border-bottom: 0;
      border-radius: 2px;
      background: transparent;
    }
  }
}

.custom-part-number-table {
  .table {
    margin-bottom: 0;

    table {
      width: 100%;

      tr {
        td,
        th {
          font-size: 12px;
          border-right: 1px solid var(--border-color-7);
          color: var(--grey-color);

          &:last-of-type {
            border-right: 0;
          }
        }

        th {
          font-weight: 800;
        }

        &:last-of-type {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

// change password
.vertical-tab {
  display: flex;

  .nav-pills {
    min-width: 300px;
    width: 300px;
    border: 1px solid var(--border-color-2);
    border-bottom: 0;
    height: fit-content;
    margin-bottom: 100px;

    .nav-link {
      border-radius: 0;
      padding: 16px 24px;
      color: var(--dark-color);
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      border-bottom: 1px solid var(--border-color-2);

      &.active {
        background-color: var(--light-grey-800);
        color: var(--dark-color);
      }
    }

    @media screen and (max-width: 767px) {
      min-width: fit-content;
      width: fit-content;
    }
  }

  .tab-content {
    flex: 1 1 auto;
    padding-left: 24px;
  }
}

.success--msg {
  margin: 0 auto;
  color: var(--dark-blue);
  font-weight: 500;
  font-size: 14px;
  text-align: center;

  img {
    width: 30px;
    margin-right: 5px;
  }
}

// pagination
.custom--paginattion {
  .p-paginator-pages {
    padding: 8px 12px;
    line-height: 16px;
    font-size: 14px;
    height: auto;
    border-radius: 0;
    font-weight: 500;
    color: var(--font-color);

    .p-highlight {
      background-color: var(--primary-color);
      color: var(--white-color);
      border-color: var(--primary-color);
    }
  }

  .p-paginator-element {
    border: 0.5px solid var(--border-color-2);
    border-radius: 0;
    padding: 8px 15px;
    height: auto;
    letter-spacing: 0px;
    color: var(--dark-color-500);
    text-transform: capitalize;
    font-weight: 500;
    line-height: 14px;
    font-size: 14px;

    span {
      &.p-3 {
        padding: 0 !important;
      }
    }

    &.p-disabled {
      color: var(--dark-grey-100);
    }
  }
}

.product--detail-iamge-slider {
  .image-gallery-slide-wrapper {
    max-width: 516px;
  }

  .image-gallery-swipe {
    width: 100%;
    height: 616px;

    .image-gallery-slides,
    .image-gallery-slide {
      height: 100%;

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.product--detail-slider {
  height: 616px;
  display: flex;
  align-items: center;

  .prduct-preview {
    width: 100% !important;
    height: 100%;

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: inherit;
    }

    .slick-slide {
      div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .product--detail-thumbnail {
    margin: -6px 0;
    margin-left: 43px;
    width: 120px;
    display: flex;
    align-items: center;

    .slick-slider {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .slick-list {
      height: 100%;
    }

    .slick-slide {
      padding: 6px 0;
      width: 120px !important;

      > div {
        height: 118px;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      &.slick-current {
        > div {
          border: 1px solid var(--primary-color);
        }
      }
    }

    .slick-arrow {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;

      &::before {
        content: url(../images/down-chevron-grey.svg);
      }

      &.slick-prev {
        top: 20px;
      }

      &.slick-next {
        bottom: 10px;
        top: unset;
        transform: rotate(180deg);
      }
    }
  }
}

.product--detail-main-block {
  .right {
    padding-left: 55px;
    flex: 1 1 auto;
  }
}

.cart--page {
  display: flex;
  align-items: flex-start;

  .left {
    flex: 1 1 auto;
    padding-right: 68px;
  }

  .right {
    max-width: 340px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .left {
      width: 100%;
      padding-right: 0;
    }

    .right {
      width: 100%;
      max-width: 100%;

      .custom-field .ant-form-item-label {
        gap: 10px;

        label,
        a {
          font-size: 18px;
        }
      }
    }
  }
}

.cart--product-table {
  .product-detail-box {
    display: flex;
    align-items: center;

    > img {
      height: 100px;
      width: 100px;
      border-radius: 4px;
      margin-right: 25px;
      object-fit: contain;
      cursor: pointer;

      & + span {
        cursor: pointer;
      }
    }

    color: var(--dark-color);
    max-width: 445px;
  }

  td {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0px;
    letter-spacing: 0.45px;
    color: var(--dark-color);
    padding: 21px 0;
    border-bottom: 1px solid var(--border-color-2);
    vertical-align: middle;

    a {
      > img {
        width: 19px;
        height: 21px;
      }
    }
  }
}

.card.custom--card {
  border: 1px solid var(--border-color-5);
  border-radius: 0;

  .card-header {
    padding: 16px 25px;
    border-bottom: 1px solid rgba(203, 203, 203, 0.5019607843);

    .title {
      margin-bottom: 0;
      font: normal normal 600 20px/26px Poppins, sans-serif;
      letter-spacing: 0.5px;
    }

    .btn-borderd--primary {
      padding: 8px 31px;
      font-size: 16px;
    }
  }

  .card-body {
    padding: 22px 25px;
  }

  &.payment--card {
    border: 0.5px solid var(--border-color-7);
    border-radius: 4px;
    margin-bottom: 15px;

    .card-body {
      padding: 20px 26px;

      .bottom--details {
        column-gap: 10px;

        label {
          letter-spacing: 0px;
          color: var(--font-color);
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          letter-spacing: 0.35px;
          color: var(--dark-color-600);
          margin-bottom: 0;
          word-break: break-all;
        }
      }
    }
  }
}

.card-logo-img {
  max-height: 20px;
}

.article--card {
  .card-header {
    height: 215px;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-body {
    time {
      letter-spacing: 0.14px;
      color: var(--font-color);
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      font-family: var(--body-fonts);
    }

    label {
      font: normal normal 600 18px/28px var(--body-fonts);
      letter-spacing: 0.18px;
      color: var(--dark-color);
    }

    p {
      letter-spacing: 0.14px;
      color: var(--font-color);
      font-size: 14px;
      line-height: 26px;
      font-family: var(--body-fonts);
      font-weight: 500;
    }
  }
}

.bg-grey {
  background: var(--grey-color-100);
}

.product--order-detail-list {
  > li {
    letter-spacing: 0.4px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    width: 40%;

    &:last-child {
      width: 20%;
    }
  }
}

.collapse--card {
  .card-body {
    min-height: 70px !important;
    height: 70px !important;
    overflow: hidden;
    display: block !important;
    transition: none !important;

    &.show {
      height: auto !important;
      min-height: 70px !important;
    }
  }
}

.my--order-product-details {
  > img {
    height: 80px;
    width: 80px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 20px;
  }

  h4 {
    letter-spacing: 0px;
    color: var(--dark-color);
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    max-width: 320px;
    margin-bottom: 0;
  }
}

.my--order-products-table {
  td {
    padding: 14px 0.5rem;
    vertical-align: top;
    border-bottom: 0.5px solid var(--border-color-2);
  }

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
}

.apply-float-link {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 0px;
  color: var(--font-color);
}

.text-input-group {
  position: relative;

  input {
    padding-right: 65px;

    & + .apply-float-link {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }
  }
}

.text-decoration {
  text-decoration: underline !important;
}

.no-address--info {
  h4 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    letter-spacing: 0px;
    color: var(--dark-color);
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--font-color);
  }
}

.address--info {
  max-width: 310px;
  margin: 0 auto;

  h4 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: var(--dark-color);
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.4px;
    color: var(--dark-color);
    font-weight: 400;
    margin-bottom: 0;
  }
}

.custom--select {
  z-index: 1056;
}

.custom-search {
  .ant-input-affix-wrapper {
    box-shadow: 0px 3px 20px var(--border-color-4);
    border: 1px solid var(--light-border-color);
    border-radius: 2px;
    height: 42px;

    svg {
      height: 20px;
      width: 20px;
      fill: var(--font-color);
    }

    input.ant-input {
      font-size: 16px;
      line-height: 38px;
      font-weight: 500;
      font-family: var(--body-fonts);
      letter-spacing: 0.24px;
      color: var(--dark-grey-100);
    }
  }
}

.list--box {
  border: 1px solid var(--light-grey-400);
  border-radius: 2px;
  padding: 0 40px;

  .doc--list {
    margin-bottom: 0;

    > li {
      padding: 20px 0;
      border-bottom: 0.5px solid var(--border-color-7);

      &:last-child {
        border-bottom: 0 !important;
      }

      a {
        text-decoration: underline;
        font-size: 16px;
        line-height: 48px;
        font-weight: 500;
        font-family: var(--body-fonts);
        letter-spacing: 0.4px;
        color: var(--dark-color);
      }
    }
  }
}

// news
.news--list {
  > li {
    padding: 16px 0;
    border-bottom: 1px solid var(--light-grey-900);

    span {
      figure {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }

    > a {
      margin-left: 164px;
      cursor: pointer;
    }

    &.more-text {
      p {
        -webkit-line-clamp: unset;
      }
    }
  }
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  > .footer {
    margin-top: auto;
  }
}

// contact
.fixed-width-container {
  max-width: 1056px;
}

.map-block {
  position: relative;
}

.address-block {
  background: var(--white-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--dark-shadow-color-3);
  border-radius: 12px;
  max-width: 272px;
  padding: 22px;
  position: absolute;
  top: 18px;
  left: 40px;

  label {
    font: normal normal 600 18px/48px Poppins, sans-serif;
    letter-spacing: 0.45px;
    color: var(--dark-color);
  }

  p {
    font: normal normal normal 14px/22px Poppins, sans-serif;
    letter-spacing: 0.35px;
    color: var(--font-color);
    margin-bottom: 0;
  }

  hr {
    border-top: 0.5px solid var(--dark-color);
    opacity: 0.3;
    margin-bottom: 0;
  }
}

img.full-width-img {
  height: 568px;
  width: 100%;
  object-fit: cover;
}

.custom-tab {
  display: flex;
  border: 1px solid var(--light-grey-400);
  border-radius: 2px;

  > li {
    .nav-link {
      border: 0;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      width: 100%;
      letter-spacing: 0px;
      color: var(--font-color);
      font-size: 16px;
      font-weight: 500;
      font-family: var(--body-fonts);
      border-radius: 0;

      &.active {
        background: var(--primary-color-1);
        color: var(--white-color);
      }
    }
  }

  &.tutorial-tab {
    max-width: 516px;
    margin: 0 auto;

    > li {
      flex: 1 1 50%;
    }
  }
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.line-2 {
    -webkit-line-clamp: 2;
  }
}

// selected payment method
.card.selected--card {
  background: #ff5e151a 0% 0% no-repeat padding-box;
  border: 0.5px solid #ff5e15 !important;
}

.news-list {
  span > p {
    justify-content: flex-start;
    column-gap: 15px;
  }

  img {
    width: auto;
    max-width: 400px;
    border-radius: 6px;
    object-fit: cover;
  }
}

.readonly-product-detail-block {
  padding-right: 76px;
}

.readonly-product-doc-block {
  width: 300px;
  position: relative;

  > div {
    margin-bottom: 32px;
  }

  h3 {
    font: normal normal 600 18px/26px Poppins, sans-serif;
    letter-spacing: 0.45px;
    color: #000000;
    margin-bottom: 15px;
  }

  .react-pdf__Document {
    width: 100%;

    .react-pdf__Page {
      width: 100%;

      .react-pdf__Page__svg {
        height: 300px !important;
        width: 298px !important;
        background: red;

        > svg {
          height: 100%;
          width: 100%;
          background-color: #f6f7f8;
        }
      }
    }

    & + nav {
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      transform: translateY(-50px);
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;

      button {
        background: #b3afaf;
        border: 0;
        font-size: 12px;
        border-radius: 4px 0 0 4px;
        color: #fff;
        cursor: pointer;

        &:last-of-type {
          border-radius: 0 4px 4px 0;
        }
      }

      p {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #6a6a6a;
        margin-bottom: 0;
      }
    }
  }

  .readonly-product-box {
    border: 1px solid #dedede;

    .product--name {
      font: normal normal 600 18px/30px Poppins, sans-serif;
      letter-spacing: 0.18px;
      color: #000000;
      margin: 0;
      padding: 18px 5px;
      text-align: center;

      a {
        color: #000000;

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    &:hover {
      nav {
        opacity: 1;
      }
    }
  }
}

// remove google translate text
.goog-te-gadget {
  font-size: 0px;
}

#google_translate_element img {
  display: none !important;
}

.multiple-multiple-item-select {
  .ant-select-selection-overflow {
    display: none;
  }
}

.interest-list {
  margin-top: 10px;
  gap: 10px;

  > li {
    background: var(--white-color-100);
    border-radius: 2px;
    padding: 6px 12px;
    color: var(--dark-color);
    font-size: 13px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    line-height: 20px;

    button {
      border: 0;
      background: transparent;
    }
  }
}

.changePinModal {
  .modal-title {
    img {
      max-width: 30px;
      margin-right: 10px;
    }
  }
}

.img-cursor {
  cursor: pointer;
}

@media (max-width: 1250px) {
  .top-navbar .navbar {
    padding: 0 0 0 25px;

    & .navbar-nav {
      column-gap: 25px;
    }
  }
}

@media (max-width: 1160px) {
  .top-navbar .navbar {
    padding: 0 0 0 25px;

    & .navbar-nav {
      column-gap: 10px;
      width: 100%;
      justify-content: space-between;
      padding-right: 30px;

      & + button.btn--primary {
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }

  .top-navbar .navbar .navbar-nav .nav-item .custom-dropdown .dropdown-toggle,
  .c-nav-link {
    font-size: 14px;
  }
}

.ant-notification {
  z-index: 1500;
}

.rght-btn {
  li {
    list-style: none;

    .prime-pad {
      padding: 18px !important;

      @media screen and (max-width: 1200px) {
        width: 200px;
      }
    }
  }
}

.top-navbar {
  .image-block {
    .d-flex.align-items-center.custom-media-block {
      display: none !important;
    }
  }

  .display1 {
    display: none;
  }
}

.ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}

.custom-dropdown {
  .dropdown-menu a {
    @media screen and (min-width: 1400px) {
      font-size: 16px;
    }
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
}

.list--box {
  &.tutorial {
    .doc--list {
      > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        a {
          line-height: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  ul {
    &.navbar-nav {
      li {
        padding: 9px 10px;
        background: var(--white-color);
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: 1px solid transparent;
        }

        a {
          font-size: 15px;
        }

        .custom-dropdown button,
        a {
          color: #000;

          &:active {
            color: #0c4886 !important;
            font-weight: 600;
          }

          &:hover {
            color: #0c4886 !important;
            font-weight: 600;
          }

          &:focus {
            color: #0c4886 !important;
            font-weight: 600;
          }
        }

        .custom-dropdown {
          .dropdown-menu {
            transform: translate3d(0px, 30px, 0px) !important;
          }

          button {
            &.show.btn-link {
              &:active {
                color: #0c4886 !important;
                font-weight: 600;
              }

              &:hover {
                color: #0c4886 !important;
                font-weight: 600;
              }

              &:focus {
                color: #0c4886 !important;
                font-weight: 600;
              }
            }

            &::after {
              filter: brightness(0) !important;
            }
          }
        }
      }
    }
  }

  .navbar-toggler {
    color: #fff !important;
    margin: 8px 0;
    background-color: #fff !important;
  }

  .rght-btn {
    background-color: var(--white-color);
  }

  .navbar-collapse {
    background-color: var(--white-color);
    border: 1px solid #ccc;
  }

  .top-navbar .navbar {
    padding: 0 25px;
  }

  .rght-btn li .prime-pad {
    width: 180px;
    padding: 8px !important;
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 6px !important;
  }

  .about-block .col-md-4.ps-5 {
    padding: 0 !important;
  }
  .navbar-toggler-icon {
    font-size: 16px !important;
  }
  .navbar-toggler {
    &:focus {
      box-shadow: none !important;
    }
  }
  button.navbar-toggler {
    margin: 11px 0;
  }
  // Request a Quote
  .submit-btn {
    text-align: center;
  }
  // My Account
  .settings-block {
    .container {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  // Product Detail
  .productdetail {
    flex-direction: column;
  }
  .readonly-product-detail-block {
    padding-right: 0px;
  }
  .readonly-product-doc-block {
    align-self: center !important;
    text-align: center !important;
  }
  figure.image.image-style-align-left {
    text-align: center;
  }
  figure.image {
    text-align: center;

    img {
      max-width: 300px;
    }
  }
  figure.table {
    height: 100% !important;
  }
  h2 {
    font-size: 22px !important;
    text-align: center;
  }
  .product--detail-main-block {
    flex-direction: column;
    .right {
      padding-left: 0px !important;
    }
  }

  // Product Detail
  .product--detail-slider {
    height: auto;
    display: flex;
    align-items: center;

    .product--detail-thumbnail {
     display: none;
   }
  }
  // Cart Page
  .cart--page {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .right {
      max-width: 100%;
      width: 100%;
    }
    .left {
      padding-right: 0px;
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  p.cmn-txt.img-width {
    figure {
      width: unset !important;

      img {
        max-width: 100% !important;
      }
    }
  }

  .top-navbar {
    padding: 15px 0;

    .d-flex.align-items-center.custom-media {
      display: none !important;
    }

    .image-block {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .d-flex.align-items-center.custom-media-block {
        display: flex !important;
      }
    }

    .container.d-flex.flex-wrap {
      flex-direction: column;
      display: flex;
      width: 100%;
      align-items: flex-start;
    }

    ul.list-unstyled.ms-auto.mb-0.d-flex.pe-3 {
      text-align: left;
      margin: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
    }

    .display {
      display: none;
    }

    .display1 {
      display: block;

      h4 {
        min-width: 130px;
      }
    }
  }

  .about-block {
    padding: 40px 20px;
  }
  .top-info-navbar {
    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      ul.list-unstyled.mb-0.me-auto.d-flex.align-items-center {
        margin: auto;
      }
    }

    .select-language-dropdown {
      .goog-te-gadget-simple {
        a {
          font-size: 13px;
        }
      }
    }
  }

  .card.custom-card.product-card {
    width: 100%;
  }

  .banner-slider.product-img-preview-slider .carousel-inner {
    height: auto;

    .carousel-item img {
      width: 500px;
    }
  }

  .list-banner-block h4 {
    font-size: 40px;
  }

  ol.breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
  }

  .custom-dropdown .dropdown-menu {
    width: 80vw;
  }

  .top-navbar ul.list-unstyled.ms-auto.mb-0.d-flex.pe-3 {
    overflow: hidden;
  }

  .top-navbar {
    .display1 {
      h4 {
        font-size: 16px;
      }
    }
  }
  // Sign Up Form Style
  .custom-modal-signup-form .modal-content .modal-header {
    padding: 20px;
  }
  .signup-form {
    padding: 5px !important;
    .ant-form-item-label {
      label {
        font-size: 16px;
      }
    }
  }
  // Tutorial Style
  .list--box {
    &.tutorial {
      padding: 0 10px;
    }
  }

  //My Cart
  .product--detail__custom-input-group {
    max-width: 200px !important;
  }
  .cart--product-table .product-detail-box {
    img {
      height: 60px;
      width: 60px;
      margin-right: 10px;
    }
  }

  // Contact Us
  .map-block {
    width: auto;
  }
  .address-block {
    max-width: 50%;
    padding: 10px;

    p {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 550px) {
  .settings-block {
    .vertical-tab {
      flex-direction: column;
      .nav {
        &.nav-pills {
          flex-direction: row !important;
          overflow: scroll;
          width: 100% !important;
          min-width: 100%;
          flex-wrap: nowrap;
          margin-bottom: 20px;
          gap: 10px;
          border: 0px;

          .nav-link {
            padding: 12px 5px;
            font-size: 12px;
            min-width: 120px !important;
            line-height: 16px;
            text-align: center;
            border: 1px solid #eee;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #f35a14;
            border-radius: 10px;
          }
        }
      }
      .tab-content {
        padding: 0;
      }
    }
    .custom-btn {
      text-align: center;
    }
  }
  .add-payment {
    .ant-col {
      width: 100%;
    }
  }
  .cart--product-table {
    .cart-table {
      td {
        padding: 4px;
        font-size: 12px;
      }
      .product-detail-box {
        flex-direction: column;
        text-align: center;
        img {
          height: 40px;
          width: 40px;
        }
        span {
          line-height: normal;
        }
      }
      .img-cursor {
        height: 16px;
        width: 16px;
      }
    }
  }
  .product--detail-slider {
    height: auto;
    width: 100%;
    align-self: center;
    margin: 0 !important;
  }
  :root .btn.btn--primary__custom {
    font-size: 12px;
    padding: 8px 20px;
  }
}

@media screen and (max-width: 350px) {
  .cart--page {
    .cart--product-table {
      .cart-table {
        .product--detail__custom-input-group .btn {
          width: 30px;
          font-size: 16px;
          padding: 2px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .list-banner-block h4 {
    font-size: 30px;
    line-height: 40px;
  }

  .ant-select.ms-auto.css-dev-only-do-not-override-1fviqcj.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }

  .about-block p.block-title-xxl.text-dark {
    text-align: center;
    font-size: 37px;
  }

  .about-block .cmn-txt.cmn-txt-md.mb-3 {
    text-align: justify;
  }
}

@media screen and (max-width: 460px) {
  .top-info-navbar {
    .container {
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          justify-content: center;
          margin: 0 !important;

          &.v-divider.mx-3 {
            display: none;
          }
        }
      }
    }
  }

  .top-navbar {
    .display1 {
      h4 {
        font-size: 14px;
      }
    }

    .image-block img.logo {
      width: 130px;
    }

    .custom-media-block {
      .cart-img {
        width: 30px;
      }
      .cart-title {
        font-size: 16px !important;
      }
    }
  }

  .banner-slider.product-img-preview-slider .carousel-inner .carousel-item img {
    width: 300px;
  }

  .list-banner-block h4 {
    font-size: 22px;
    line-height: 26px;
  }
  .custom-dropdown .dropdown-menu {
    width: 77vw !important;
  }
}

.container.pb-5 .col-12.mb-3.mt-3 {
  text-align: center;
}

.readonly_category {
  .card.custom-card {
    width: 100%;

    img {
      width: 230px;
    }
  }
}

.service-manual-block {
  background: url(../images/service.png);
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 200px 0 0 0;

  .content-block {
    padding-left: 30px;

    @media screen and (max-width: 992px) {
      h4 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  .image-inner {
    position: relative;

    img {
      position: relative;
      top: -94px;
      transform: scale(1.1);
      right: 40px;

      @media screen and (min-width: 1400px) {
        top: -114px;
      }

      @media screen and (max-width: 1200px) {
        top: -89px;
        transform: unset;
        right: 0;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    margin: 180px 0 0 0;
  }

  @media screen and (max-width: 992px) {
    // background: none;

    .custom-one {
      order: 2;
      // background: linear-gradient(to bottom, var(--dark-blue) 100%, var(--white-color) 100px);
      padding: 0 0 30px 0;
      margin-bottom: 0;
    }

    .custom-two {
      .image-inner {
        text-align: center;
      }

      img {
        width: 400px;
        top: -79px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    margin-top: 120px;
  }
}

@media screen and (max-width: 414px) {
  .custom-modal-login-form .modal-content .modal-header {
    padding: 20px;
  }
  .login-form {
    padding: 20px !important;
    .ant-form-item-label {
      label {
        font-size: 16px;
      }
    }
    .custom-form-control.form-control {
      font-size: 16px;
    }
    .ms-2.a-r-o-link {
      display: block;
    }
    .sign-in-capta {
      > div {
        > div {
          width: 305px !important;
          height: auto !important;

          iframe {
            width: 73% !important;
            border: 1px solid #ccc;
            border-radius: 4px;

            .rc-anchor-content {
              display: flex;
              justify-content: flex-start;
            }
          }

          // .rc-anchor-normal {
          //     .rc-anchor-checkbox-label {
          //         width: 72px !important;
          //         font-size: 11px;
          //     }
          //     .rc-anchor-content {
          //         height: 74px;
          //         width: 145px;
          //     }
          // }
        }
      }
      .rc-anchor-logo-portrait {
        margin: 6px 0 3px -53px !important;
        width: 58px !important;
      }
      .rc-anchor-normal .rc-anchor-content {
        width: 123px !important;
      }
      .rc-anchor-pt a {
        display: none !important;
      }
    }
  }

  // Sign Up Form Style
  .signup-form {
    .sign-up-capta {
      > div {
        > div {
          width: 305px !important;
          height: auto !important;

          iframe {
            width: 73% !important;
            border: 1px solid #ccc;
            border-radius: 4px;

            .rc-anchor-content {
              display: flex;
              justify-content: flex-start;
            }
          }

          // .rc-anchor-normal {
          //     .rc-anchor-checkbox-label {
          //         width: 72px !important;
          //         font-size: 11px;
          //     }
          //     .rc-anchor-content {
          //         height: 74px;
          //         width: 145px;
          //     }
          // }
        }
      }
      .rc-anchor-logo-portrait {
        margin: 6px 0 3px -53px !important;
        width: 58px !important;
      }
      .rc-anchor-normal .rc-anchor-content {
        width: 123px !important;
      }
      .rc-anchor-pt a {
        display: none !important;
      }
    }
    .modal-title.h4 {
      font-size: 20px !important;
    }
  }
  .news--list {
    img {
      max-width: 200px;
    }
  }
}

a.text-muted {
  color: #bbbbbb !important;
}
